import { Component } from '@angular/core';

@Component({
  selector: 'app-bag-icon',
  standalone: true,
  imports: [],
  templateUrl: './bag-icon.component.html',
  styleUrl: './bag-icon.component.scss'
})
export class BagIconComponent {

}
