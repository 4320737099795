import { Component } from '@angular/core';

@Component({
  selector: 'app-hotel-icon',
  standalone: true,
  imports: [],
  templateUrl: './hotel-icon.component.html',
  styleUrl: './hotel-icon.component.scss'
})
export class HotelIconComponent {

}
