import { Component } from '@angular/core';

@Component({
  selector: 'app-three-dot-icon',
  standalone: true,
  imports: [],
  templateUrl: './three-dot-icon.component.html',
  styleUrl: './three-dot-icon.component.scss'
})
export class ThreeDotIconComponent {

}
