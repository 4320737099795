<div class="mt-2">
  <div class="products-cart" [ngClass]="{'limitTextHeight': isReadMore}">
    <div class="d-flex flex-row">
      <div class="cart-product-image">
        <img [src]="s3Base  + data.productId.thumbnail.savedName" alt="" width="100%" height="100%">
      </div>
      <div class="cart-product-details-container">

        <span [style]="{'font-size':'12px','font-weight':'500'}">{{data.productName}}</span>

        <span [style]="{'font-size':'10px','font-weight':'400','color':'#A3A3A3'}">
          {{data.label[0]}}
        </span>

        <span class="sp">₹{{data.productId.price.minPrice}} <span *ngIf="data.productId.price.minPrice != data.productId.price.mrp" class="mrp">₹{{data.productId.price.mrp}}</span></span>

      </div>
    </div>
    <div class="text-center product-cart-price-inc-dec-container">
      @if(data.productId?.stock?.availableQuantity > 0){
      <app-product-quantity-control [data]="data"></app-product-quantity-control>
      } @else {
      <span class="stock">Out of Stock</span>
      }
      <!-- <div style="font-size: small; cursor: pointer; color: red;"
                                          (click)="removeProduct(p)"> -->
      <div class="remove" (click)="removeProductFromCart(data)">
        Remove</div>
    </div>
  </div>
  @if(maxQtyAllowed > 0 && data.quantity > maxQtyAllowed){
  <div class="stock"><span class="material-icons fs-16 align-middle">
    error_outline
    </span> You can buy only up to {{maxQtyAllowed}} unit(s) of this product &nbsp;&nbsp; <button class="btn btn-outline-primary fs-12 py-1 px-2" (click)="changeQuantity()">Change</button></div>
  }
</div>