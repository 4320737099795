import { Component } from '@angular/core';

@Component({
  selector: 'app-house-icon',
  standalone: true,
  imports: [],
  templateUrl: './house-icon.component.html',
  styleUrl: './house-icon.component.scss'
})
export class HouseIconComponent {

}
