import { Component } from '@angular/core';

@Component({
  selector: 'app-ping-icon',
  standalone: true,
  imports: [],
  templateUrl: './ping-icon.component.html',
  styleUrl: './ping-icon.component.scss'
})
export class PingIconComponent {

}
