<!-- <section class="py-3"> -->
<div class="app-container">
    <div *ngIf="isHealthPackagePurchased" class="mb-3 col-md-5">
        <div class="p-3 p-sm-5 calendarCont minHeight100 text-center">
            <img src="/assets/images/consultation/appt-calendar.png" width="400" height="265"
                style="max-width: 100%;height: auto;" class="mb-5 mt-4"
                alt="Select date and slot for consultation appointment">
            <h5 class="color-green mb-3">Thank You! For Choosing Our Health Package</h5>
            <p class="font-18 b-5">To initiate your package please book your first consultation</p>
        </div>
    </div>
    <div class="mb-3" [style]="{'height':'100%'}">
        <div class="calendarCont">
            <div *ngIf="!isFreeReschedule" class="alert alert-warning mb-3">
                <strong>ATTENTION!</strong> You have to pay the fee to reschedule appointment on the chosen
                date. Reschedule
                is free only within {{freeWithinDays}} days from the appointment date.
            </div>
            <div class="time-slot-title-container">
                <span class="time-slot-title">Select time slot</span>
                <!-- <span [style]="{'margin-left': '10px','margin-top': '10px'}">Book Your Appointment</span> -->
            </div>
            <div class="time-slot-devider"></div>
            <div [ngClass]="{'d-none': step == 'mode'}"
                [style]="{'display':'flex','flex-direction':'column','padding':'16px'}">
                <!-- <div class="mt-2"
                        [style]="{'display':'flex','justify-content':'center','font-size':'18px','font-weight':'550'}">
                        <span>Select Date & Time</span>
                    </div> -->
                <!-- calender -->
                <div>
                    <app-date-slot-selector [config]="{minDate: minDate, maxDate: maxDate}" [slots]="availableTimeSlots"
                        (changeDate)="onChangeDate($event)" (changeSlot)="onSelectSlot($event)"
                        (continue)="onContinue()"></app-date-slot-selector>
                </div>
            </div>
            <ng-container *ngIf="step == 'mode'">
                <div class="d-flex flex-column" [style]="{'padding': '15px'}">
                    <div class="mt-3">
                        <h3 class="mb-0" [style]="{'font-size':'16px','font-weight':'600'}">Select Appointment Mode
                        </h3>
                    </div>
                    <form [formGroup]="modeFG" (submit)="onConfirm()">
                        <div class="d-flex mt-4 mb-3">
                            <!-- {{consultantDetails | json}} -->
                            <label *ngIf="consultantDetails?.appointmentMode?.isAudio"
                                class="fancyRadio consultation-mode">
                                <input type="radio" name="appointmentMode" value="audio"
                                    formControlName="appointmentMode" />
                                <span></span>&nbsp;
                                <span>Audio Call</span>
                            </label>
                            <label *ngIf="consultantDetails?.appointmentMode?.isVideo"
                                class="fancyRadio consultation-mode">
                                <input type="radio" name="appointmentMode" value="video"
                                    formControlName="appointmentMode" />
                                <span></span>&nbsp;
                                <span>Video Call</span>
                            </label>
                            <label *ngIf="consultantDetails?.appointmentMode?.isChat"
                                class="fancyRadio consultation-mode">
                                <input type="radio" name="appointmentMode" value="chat"
                                    formControlName="appointmentMode" />
                                <span></span>&nbsp;
                                <span>Messaging</span>
                            </label>
                            <div *ngIf="!consultantDetails?.appointmentMode?.isAudio && !consultantDetails?.appointmentMode?.isVideo && !consultantDetails?.appointmentMode?.isChat"
                                class="fs-6 text-danger text-center w-100">No mode available!</div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="submit" class="btn btn-outline-primary px-5 py-2 mt-4"
                                [disabled]="modeFG.invalid">Confirm</button>
                        </div>
                    </form>
                </div>

            </ng-container>
        </div>
    </div>
</div>
<!-- </section> -->